import React, { useState } from 'react'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer';
import Switcher from '../../components/switcher';
import { Link } from 'react-router-dom';
import HomeSecond from '../home-second';
import img1 from '../../assets/images/aimaketplace.jpg'
import {MdOutlineArrowForward, RxQuestionMarkCircled, PiBookBookmarkBold, HiOutlineCog6Tooth,SiAboutdotme} from "../../assets/icons/vander"
import AIMarket from '../Ai-market';
import LOLDAO from '../../assets/images/lol-new-img.png'

export default function AIMarketplace() {
    const accordionData = [
        {
            title: 'How does it work ?',
            content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        },
        {
            title: 'Do I need a designer to use Giglink ?',
            content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        },
        {
            title: 'What do I need to do to start selling ?',
            content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        },
        {
            title: 'What happens when I receive an order ?',
            content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.'
        },
    ]
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }

    const spanStyle = {
        background: `linear-gradient(90deg, #fe3499, #ffffff, #fb53a4)`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        display: 'inline-block',
    };

    return (
        <>
            <Navbar />
            <section className="relative table w-full py-36 bg-cover" style={{ backgroundImage: `url(${img1})`,backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center glassmorphic6">
                        <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4"><span style={spanStyle}>Discover Your Next AI Solution</span> Welcome to the AI Marketplace</h4>
                        {/* <p className="text-slate-400 max-w-xl mx-auto">Explore a marketplace where AI models are uniquely represented as NFTs for intuitive trading and creation.</p> */}
                        {/* <div className="mt-6">
                            <Link to="/" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full me-2 mt-2" style={{cursor:'pointer'}}>Begin Your Journey</Link>
                        </div> */}
                        <div className="mt-4" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                    <img src={LOLDAO} style={{ width: '120px', height: 'auto' }} alt='LOL' />
                  </div>
                  <div className="mt-2" style={{marginLeft: '13px', display: 'flex', justifyContent: 'center', alignContent: 'center',fontSize:'16px' }}>
                    <p className="text-slate-400 max-w-xl mx-auto">DATA IS THE NEW OIL</p>
                  </div>
                    </div>
                </div>
            </section>

            {/* <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div> */}
            <section className="relative md:py-24 py-16" style={{marginTop:'-200px',marginBottom:'-50px'}}>
                <div className="container">
                    <AIMarket/>     
                </div>
            </section>
            <Footer />
            {/* <Switcher /> */}
        </>
    )
}
