import React, { useState } from 'react';
import axios from 'axios';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import img1 from '../assets/images/contact.png';
import { FaRegEnvelope, BsTelephone, FiMapPin } from "../assets/icons/vander";
import { Link } from 'react-router-dom';
import LOLDAO from '../assets/images/lol-new-img.png'
import Modal from 'react-modal';
import NavbarNew from '../components/navbar-new';

export default function Contact() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Function to toggle the modal visibility
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const initialFormData = {
        name: '',
        email: '',
        phoneNumber: '',
        job: '',
        companyName: '',
        industry: 'Technology', 
        companySize: '1-10 employees',
        interestedAreas: [],
        SessionFormat: '',
        findUs: 'Email',
        qnsOrComments: '',
        subscribeNews: false,
        privacyAndPolicy: false
    };

    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleCheckboxChange = (e) => {
        const { value } = e.target;
        setFormData(prevState => {
            const interestedAreas = [...prevState.interestedAreas];
            if (interestedAreas.includes(value)) {
                const index = interestedAreas.indexOf(value);
                interestedAreas.splice(index, 1);
            } else {
                interestedAreas.push(value);
            }
            return {
                ...prevState,
                interestedAreas
            };
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
       
        let interestedArea = formData.interestedAreas.join(',');
        
        let registerData = { ...formData, interestedArea };
        
        console.log(registerData);
       
        try {
            const response = await axios.post('https://api.loldao.skyniche.website/user/register', registerData);
            console.log('Response:', response.data);
            setIsModalOpen(true);
            setFormData(initialFormData);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const spanStyle = {
        background: `linear-gradient(90deg, #f8a8c6, #f8a8c6, #fb53a4)`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        display: 'inline-block',
    };

    // const gif7 = "https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExcGI4MWh0ZXY1Yzk4Y2c5MGxweTkyNDNwZjJubnYzNncyZ3oxd281diZlcD12MV9pbnRlcm5naWZfYnlfaWQmY3Q9Zw/3ov9k1x0lY0sAeOc8g/giphy.gif";
    const gif7 = "";

    return (
        <>
            <Navbar />
            {/* backgroundImage:`url(${img1})`, */}
            <section className="relative table w-full py-36 bg-no-repeat" style={{backgroundSize: 'cover', backgroundPosition: 'center'}}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10 glassmorphic66">
                    <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4"><span style={spanStyle}>Register </span></h4>
                    <p className="text-slate-400 max-w-xl mx-auto text-3xl lg:text-4xl">Register Today and Join the Revolution </p>
                    <div className="mt-4" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                    <img src={LOLDAO} style={{ width: '120px', height: 'auto' }} alt='LOL' />
                  </div>
                  <div className="mt-2" style={{marginLeft: '13px', display: 'flex', justifyContent: 'center', alignContent: 'center',fontSize:'16px' }}>
                    <p className="text-slate-400 max-w-xl mx-auto">DATA IS THE NEW OIL</p>
                  </div>
                    </div>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container" style={{marginBottom:'-10px',marginTop:'-180px'}}>
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]" >
                        <div className="lg:col-span-12 md:col-span-12 mt-8 md:mt-0 order-2 md:order-1" >
                            <div className="lg:me-5">
                                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6"  style={{ padding: '20px', backgroundImage: `url(${gif7})`, borderRadius: '18px', objectFit: 'cover' }}>
                                    <form method="post" name="myForm" id="myForm" onSubmit={handleFormSubmit} style={{ padding: '20px', backdropFilter: 'blur(1000px)', WebkitBackdropFilter: 'blur(1000px)', backgroundColor: '#ffffff1a', boxShadow: '0 0 80px #0000004d', borderRadius: '10px',}}>
                                        <p className="mb-0" id="error-msg"></p>
                                        <div id="simple-msg"></div>
                                        <h3 className="mb-6 text-3xl leading-snug font-medium">Personal Information :</h3>
                                        <div className="grid lg:grid-cols-12 lg:gap-6">
                                            <div className="lg:col-span-4 mb-5" >
                                                <div className="text-start">
                                                    <label htmlFor="name" className="font-semibold">Full Name: </label>
                                                    <input id="name" name="name" type="text" required className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-full outline-none border border-violet-600 focus:border-violet-600 dark:border-violet-600 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Enter your full name :" value={formData.name} onChange={handleChange} />
                                                </div>
                                            </div>

                                            <div className="lg:col-span-4 mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="email" className="font-semibold">Email Address: </label>
                                                    <input id="email" name="email" type="email" required className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-full outline-none border border-violet-600 focus:border-violet-600 dark:border-violet-600 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Enter your email address :" value={formData.email} onChange={handleChange} />
                                                </div>
                                            </div>
                                            <div className="lg:col-span-4 mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="phoneNumber" className="font-semibold">Phone Number: <span className="text-red-600">*</span></label>
                                                    <input id="phoneNumber" name="phoneNumber" required type="number" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-full outline-none border border-violet-600 focus:border-violet-600 dark:border-violet-600 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Enter your phone number :" value={formData.phoneNumber} onChange={handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <h3 className="mb-6 text-3xl leading-snug font-medium">Professional Information :</h3>
                                        <div className="grid lg:grid-cols-12 lg:gap-6" >
                                            <div className="lg:col-span-6 mb-5" >
                                                <div className="text-start">
                                                    <label htmlFor="job" className="font-semibold">Job Title: <span className="text-red-600">*</span></label>
                                                    <input id="job" name="job" type="text" required className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-full outline-none border border-violet-600 focus:border-violet-600 dark:border-violet-600 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Enter your job title :" value={formData.job} onChange={handleChange} />
                                                </div>
                                            </div>
                                            <div className="lg:col-span-6 mb-5" >
                                                <div className="text-start">
                                                    <label htmlFor="companyName" className="font-semibold">Company Name: </label>
                                                    <input id="companyName" name="companyName" type="text" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-full outline-none border border-violet-600 focus:border-violet-600 dark:border-violet-600 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Enter your company name :" value={formData.companyName} onChange={handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid lg:grid-cols-12 lg:gap-6" >
                                            <div className="lg:col-span-6 mb-5" >
                                                <label className="font-semibold">Industry : </label>
                                                <select name="industry" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-full outline-none border border-violet-600 focus:border-violet-600 dark:border-violet-600 dark:focus:border-violet-600 focus:ring-0 mt-2" value={formData.industry} onChange={handleChange} >
                                                    <option className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2">Technology</option>
                                                    <option className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2">Finance</option>
                                                    <option className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2">Healthcare</option>
                                                    <option className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2">Education</option>
                                                    <option className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2">Retail</option>
                                                    <option className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2">Manufacturing</option>
                                                    <option className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2">Other</option>
                                                </select>
                                            </div>
                                            <div className="lg:col-span-6 mb-5" >
                                                <label className="font-semibold">Company Size : </label>
                                                <select name="companySize" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-full outline-none border border-violet-600 focus:border-violet-600 dark:border-violet-600 dark:focus:border-violet-600 focus:ring-0 mt-2" value={formData.companySize} onChange={handleChange} >
                                                    <option className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2">1-10 employees</option>
                                                    <option className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2">51-200 employees</option>
                                                    <option className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2">201-500 employees</option>
                                                    <option className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2">501-1000 employees</option>
                                                    <option className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2">1000+ employees</option>
                                                </select>
                                            </div>
                                        </div>
                                        <h3 className="mb-2 text-3xl leading-snug font-medium">Event Participation :</h3>
                                        <h6 className="mb-3 text-2xl leading-snug font-medium">Areas of Interest</h6>
                                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                                            <div className="col-span-1 sm:col-span-1 md:col-span-1 items-center mb-4">
                                                <input style={{marginTop:'-2px'}} className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" name="interestedAreas" value="Machine Learning" id="noti1" checked={formData.interestedAreas.includes('Machine Learning')} onChange={handleCheckboxChange} />
                                                <label className="form-check-label" htmlFor="noti1"></label>
                                                <label className="font-semibold" style={{marginLeft:'10px',}}>Machine Learning</label>
                                            </div>
                                            <div className="col-span-1 sm:col-span-1 md:col-span-1 items-center mb-4">
                                                <input style={{marginTop:'-2px'}} className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" name="interestedAreas" value="AI and Finance" id="noti2" checked={formData.interestedAreas.includes('AI and Finance')} onChange={handleCheckboxChange} />
                                                <label className="form-check-label" htmlFor="noti2"></label>
                                                <label className="font-semibold" style={{marginLeft:'10px',}}>AI and Finance</label>
                                            </div>
                                            <div className="col-span-1 sm:col-span-1 md:col-span-1 items-center mb-4">
                                                <input style={{marginTop:'-2px'}} className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" name="interestedAreas" value="Computer Vision" id="noti3" checked={formData.interestedAreas.includes('Computer Vision')} onChange={handleCheckboxChange} />
                                                <label className="form-check-label" htmlFor="noti3"></label>
                                                <label className="font-semibold" style={{marginLeft:'10px',}}>Computer Vision</label>
                                            </div>
                                            <div className="col-span-1 sm:col-span-1 md:col-span-1 items-center mb-4">
                                                <input style={{marginTop:'-2px'}} className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" name="interestedAreas" value="AI Ethics" id="noti4" checked={formData.interestedAreas.includes('AI Ethics')} onChange={handleCheckboxChange} />
                                                <label className="form-check-label" htmlFor="noti4"></label>
                                                <label className="font-semibold" style={{marginLeft:'10px',}}>AI Ethics</label>
                                            </div>
                                            <div className="col-span-1 sm:col-span-1 md:col-span-1 items-center mb-4">
                                                <input style={{marginTop:'-2px'}} className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" name="interestedAreas" value="AI in Business" id="noti5" checked={formData.interestedAreas.includes('AI in Business')} onChange={handleCheckboxChange} />
                                                <label className="form-check-label" htmlFor="noti5"></label>
                                                <label className="font-semibold" style={{marginLeft:'10px',}}>AI in Business</label>
                                            </div>
                                            <div className="col-span-1 sm:col-span-1 md:col-span-1 items-center mb-4">
                                                <input style={{marginTop:'-2px'}} className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" name="interestedAreas" value="AI and Healthcare" id="noti6" checked={formData.interestedAreas.includes('AI and Healthcare')} onChange={handleCheckboxChange} />
                                                <label className="form-check-label" htmlFor="noti6"></label>
                                                <label className="font-semibold" style={{marginLeft:'10px',}}>AI and Healthcare</label>
                                            </div>
                                            <div className="col-span-1 sm:col-span-1 md:col-span-1 items-center mb-4">
                                                <input style={{marginTop:'-2px'}} className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" name="interestedAreas" value="Natural Language Processing" id="noti7" checked={formData.interestedAreas.includes('Natural Language Processing')} onChange={handleCheckboxChange} />
                                                <label className="form-check-label" htmlFor="noti7"></label>
                                                <label className="font-semibold" style={{marginLeft:'10px',}}>Natural Language Processing</label>
                                            </div>
                                            <div className="col-span-1 sm:col-span-1 md:col-span-1 items-center mb-4">
                                                <input style={{marginTop:'-2px'}} className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" name="interestedAreas" value="Other" id="noti8" checked={formData.interestedAreas.includes('Other')} onChange={handleCheckboxChange} />
                                                <label className="form-check-label" htmlFor="noti8"></label>
                                                <label className="font-semibold" style={{marginLeft:'10px',}}>Other</label>
                                            </div>
                                        </div>
                                        <h6 className="mb-3 text-2xl leading-snug font-medium">Preferred Session Format</h6>
                                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                                            <div className="col-span-1 sm:col-span-1 md:col-span-1 items-center mb-4">
                                                <input className="form-radio rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="radio" value="keynote" id="radio1" name="SessionFormat" checked={formData.SessionFormat === 'keynote'} onChange={handleChange} />
                                                <label className="form-radio-label" htmlFor="radio1"></label>
                                                <label className="font-semibold" style={{marginLeft:'10px'}}>Keynote Speeches</label>
                                            </div>
                                            <div className="col-span-1 sm:col-span-1 md:col-span-1 items-center mb-4">
                                                <input className="form-radio rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="radio" value="panel" id="radio2" name="SessionFormat" checked={formData.SessionFormat === 'panel'} onChange={handleChange} />
                                                <label className="form-radio-label" htmlFor="radio2"></label>
                                                <label className="font-semibold" style={{marginLeft:'10px'}}>Panel Discussions</label>
                                            </div>
                                            <div className="col-span-1 sm:col-span-1 md:col-span-1 items-center mb-4">
                                                <input className="form-radio rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="radio" value="workshop" id="radio3" name="SessionFormat" checked={formData.SessionFormat === 'workshop'} onChange={handleChange} />
                                                <label className="form-radio-label" htmlFor="radio3"></label>
                                                <label className="font-semibold" style={{marginLeft:'10px'}}>Workshops</label>
                                            </div>
                                            <div className="col-span-1 sm:col-span-1 md:col-span-1 items-center mb-4">
                                                <input className="form-radio rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="radio" value="networking" id="radio4" name="SessionFormat" checked={formData.SessionFormat === 'networking'} onChange={handleChange} />
                                                <label className="form-radio-label" htmlFor="radio4"></label>
                                                <label className="font-semibold" style={{marginLeft:'10px'}}>Networking Sessions</label>
                                            </div>
                                            <div className="col-span-1 sm:col-span-1 md:col-span-1 items-center mb-4">
                                                <input className="form-radio rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="radio" value="exhibition" id="radio5" name="SessionFormat" checked={formData.SessionFormat === 'exhibition'} onChange={handleChange} />
                                                <label className="form-radio-label" htmlFor="radio5"></label>
                                                <label className="font-semibold" style={{marginLeft:'10px'}}>Exhibitions</label>
                                            </div>
                                        </div>

                                        <div className="lg:col-span-6 mb-5 mt-2" >
                                            <label className="font-semibold">How did you hear about us? : </label>
                                            <select name="findUs" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:text-slate-200 rounded-full outline-none border border-violet-600 focus:border-violet-600 dark:border-violet-600 dark:focus:border-violet-600 focus:ring-0 mt-2" value={formData.findUs} onChange={handleChange}>
                                                <option className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2">GenAI Summit</option>
                                                <option className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2">Email</option>
                                                <option className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2">Social Media</option>
                                                <option className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2">Website</option>
                                                <option className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2">Referral</option>
                                                <option className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2">Other</option>
                                            </select>
                                        </div>
                                        <h3 className="mb-6 text-2xl leading-snug font-medium">Additional Information :</h3>
                                        <div className="grid grid-cols-1">
                                            <div className="mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="qnsOrComments" className="font-semibold">Questions or Comments:</label>
                                                    <textarea id="qnsOrComments" name="qnsOrComments" className="form-input w-full text-[15px] py-2 px-3 h-28 bg-transparent dark:text-slate-200 rounded-2xl outline-none border border-violet-600 focus:border-violet-600 dark:border-violet-600 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Enter any questions or comments you have :" value={formData.qnsOrComments} onChange={handleChange} ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1">
                                            <label className="font-semibold mb-3">Subscribe to Newsletter :</label>
                                            <div className="col-span-1 sm:col-span-1 md:col-span-1 items-center mb-4">
                                                <input style={{marginTop:'-2px'}} className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" name="subscribeNews" id="subscribeNews" checked={formData.subscribeNews} onChange={handleChange} />
                                                <label className="form-check-label" htmlFor="subscribeNews"></label>
                                                <label className="font-semibold" style={{marginLeft:'10px',}}>Yes, I would like to receive updates and newsletters.</label>
                                            </div>
                                            <label className="font-semibold mb-3">Agreement to Terms and Privacy Policy :</label>
                                            <div className="col-span-1 sm:col-span-1 md:col-span-1 items-center mb-6">
                                                <input style={{marginTop:'-2px'}} className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-violet-600 focus:border-violet-600/30 focus:ring focus:ring-offset-0 focus:ring-violet-600/20 focus:ring-opacity-50" type="checkbox" name="privacyAndPolicy" id="privacyAndPolicy" checked={formData.privacyAndPolicy} onChange={handleChange} />
                                                <label className="form-check-label" htmlFor="privacyAndPolicy"></label>
                                                <label className="font-semibold" style={{marginLeft:'10px',}}>I agree to the terms and privacy policy.</label>
                                            </div> 
                                        </div>
                                        <button type="submit" id="submit" name="send" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full justify-center flex items-center">Register Now</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={toggleModal}
                    contentLabel="Popup"
                    className="modal"
                    overlayClassName="overlay">

                    {/* Content of the modal */}
                    <div className="popup-content" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '120px' }}>
                        <p>Your Form Has Been Registered Succesfully</p>
                        <br />
                        <button className='btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full' onClick={toggleModal}>
                            OK
                        </button>
                    </div>
                </Modal>

                <div className="container mt-24">
                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
                        <div className="text-center px-6 mt-6">
                            <div className="w-20 h-20 bg-violet-600/5 text-white rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                <BsTelephone className='text-xxl'/>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title text-xl font-semibold">Phone</h5>
                                <p className="text-slate-400 mt-3">Use the below number to call us</p>

                                <div className="mt-5">
                                    <Link to="tel:4192710396" className="btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out">4192710396</Link>
                                </div>
                            </div>
                        </div>

                        <div className="text-center px-6 mt-6">
                            <div className="w-20 h-20 bg-violet-600/5 text-white rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                               <FaRegEnvelope className='text-xxl'/>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title text-xl font-semibold">Email</h5>
                                <p className="text-slate-400 mt-3">Contact us through Email</p>

                                <div className="mt-5">
                                    <Link to="mailto:troston@loldao.ai" className="btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out">troston@loldao.ai</Link>
                                </div>
                            </div>
                        </div>

                        <div className="text-center px-6 mt-6">
                            <div className="w-20 h-20 bg-violet-600/5 text-white rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                <FiMapPin className='text-xxl'/>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title text-xl font-semibold">Location</h5>
                                <p className="text-slate-400 mt-3">3073 Morin PT St Erie<br /> MI 48133</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}
