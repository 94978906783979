import React, { useState } from 'react'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer';
import Switcher from '../../components/switcher';
import { Link } from 'react-router-dom';
import HomeSecond from '../home-second';
import img1 from '../../assets/images/AINew1.jpeg'
// import image from '../../assets/images/items/1.gif';
import image1 from '../../assets/images/items/1.gif';
// import image2 from '../../assets/images/items/3.gif';
import image3 from '../../assets/images/items/3.jpg';
import image4 from '../../assets/images/items/2.gif';
import image5 from '../../assets/images/items/4.jpg';
import image6 from '../../assets/images/items/3.gif';
import GalleryItems from '../../components/Gallery-Items';
import {LuSearch, PiWalletBold, BsTag, PiFireSimpleBold, FiHeart, PiBrowsersLight,FiUsers,VscListFlat, PiMusicNotesBold, LuCamera,FaRegPaperPlane} from "../../assets/icons/vander"
import DiscoverItems from '../../components/discover-items';
import GalleryItems2 from '../../components/Gallery-Items-2';
import LOLDAO from '../../assets/images/lol-new-img.png'

export default function NFTGallery() {

    const image = 'https://d1mjtvp3d1g20r.cloudfront.net/2022/02/03095227/GAL-gif.gif'
    const image2 = 'https://lh3.googleusercontent.com/kZu6cd9wx00eBWnZ0ED8uMDj2fvGC_fQk01509fOGdwD4OzJq22kqljxHLy9vGocy5sVJwvZ8FjYOTf9BD7Hpmypz_akPFQIcvOM'
    const spanStyle = {
        background: `linear-gradient(90deg, #fe3499, #ffffff, #fb53a4)`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        display: 'inline-block',
    };

    return (
        <>
            <Navbar />
            <section className="relative table w-full py-36 bg-cover" style={{ backgroundImage: `url(${img1})`,backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center glassmorphic4">
                        <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4">NFT <span style={spanStyle}> Gallery</span></h4>
                        <p className="text-slate-400 max-w-xl mx-auto">Joy Marketplace is an innovative NFT gallery that celebrates digital creativity and artistic expression. As a cutting-edge platform, Joy Marketplace provides a curated space for artists to showcase their unique and diverse NFT creations, ranging from digital art and animations to virtual collectibles.</p>
                        <div className="mt-4" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                    <img src={LOLDAO} style={{ width: '120px', height: 'auto' }} alt='LOL' />
                  </div>
                  <div className="mt-2" style={{marginLeft: '13px', display: 'flex', justifyContent: 'center', alignContent: 'center',fontSize:'16px' }}>
                    <p className="text-slate-400 max-w-xl mx-auto">DATA IS THE NEW OIL</p>
                  </div>
                    </div>
                </div>
            </section>

            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div> 
            <div style={{marginTop:'-60px'}}>
            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="md:flex">
               
            <div className="lg:w-3/5 md:w-1/2 md:pe-4 mt-10">
                <div className="group rounded-xl bg-white dark:bg-slate-900 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-800 overflow-hidden ease-in-out duration-500 w-full mx-auto lg:max-w-2xl">
                    <div className="lg:flex">
                        <div className="relative md:shrink-0">
                            <img className="h-full w-full object-cover lg:w-48" src={image} alt="" />
                        </div>
                        <div className="p-6 w-full">
                            <ul className="flex justify-between items-center list-none pb-6">
                                <li className="block items-center">
                                    <span className="bg-slate-900 text-white dark:bg-slate-800 text-[12px] px-2.5 py-1 font-semibold rounded-full h-5">Discover Digital Artistry</span>
                                </li>
                            </ul>

                            <Link to="#" className="font-bold lg:leading-snug leading-snug text-2xl lg:text-3xl"><span style={spanStyle}>A World of Digital </span> Wonders Awaits</Link>

                            <div className="pt-6">
                            <p className="text-slate-400 max-w-xl mx-auto glassmorphic5">Step into our NFT Gallery where each AI model's essence is captured as unique digital art. Explore an array of stunning NFTs, each representing an AI model's functionality and innovation. From abstract visualizations to thematic representations, find beauty in technology.</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="group rounded-xl bg-white dark:bg-slate-900 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-800 overflow-hidden ease-in-out duration-500 w-full mx-auto lg:max-w-2xl mt-[30px]">
                    <div className="lg:flex">
                        <div className="relative md:shrink-0">
                            <img className="h-full w-full object-cover lg:w-48" src={image1} alt="" />
                        </div>
                        <div className="p-6 w-full">
                            <ul className="flex justify-between items-center list-none pb-6">
                                <li className="block items-center">
                                    <span className="bg-slate-900 text-white dark:bg-slate-800 text-[12px] px-2.5 py-1 font-semibold rounded-full h-5">Own a Piece of AI</span>
                                </li>
                            </ul>

                            <Link to="#" className="font-bold lg:leading-snug leading-snug text-2xl lg:text-3xl"><span style={spanStyle}>Collect AI-Driven</span> Art</Link>

                            <div className="pt-6">
                            <p className="text-slate-400 max-w-xl mx-auto glassmorphic5">Each NFT in our gallery is more than just visual appeal; it's a piece of AI creativity. Own and collect these digital artworks, and embrace the fusion of art and AI. Our collection is constantly updated, offering fresh and intriguing art pieces.</p>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="group rounded-xl bg-white dark:bg-slate-900 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-800 overflow-hidden ease-in-out duration-500 w-full mx-auto lg:max-w-2xl mt-[30px]">
                    <div className="lg:flex">
                        <div className="relative md:shrink-0">
                            <img className="h-full w-full object-cover lg:w-48" src={image2} alt="" />
                        </div>
                        <div className="p-6 w-full">
                            <ul className="flex justify-between items-center list-none pb-6">
                                <li className="block items-center">
                                    <span className="bg-slate-900 text-white dark:bg-slate-800 text-[12px] px-2.5 py-1 font-semibold rounded-full h-5">Secure and Transparent Trading</span>
                                </li>
                            </ul>

                            <Link to="#" className="font-bold lg:leading-snug leading-snug text-2xl lg:text-3xl"><span style={spanStyle}>Trade NFTs with </span> Ease and Trust</Link>

                            <div className="pt-6">
                            <p className="text-slate-400 max-w-xl mx-auto glassmorphic5">Joy's blockchain-enabled platform ensures that trading NFTs is both secure and transparent. Experience hassle-free transactions with immutable records, preserving the authenticity and ownership of your digital assets.</p>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky top-20 mt-10">
                                <h5 className="text-lg font-semibold">Filter by</h5>

                                <div className="form-icon relative mt-4">
                                    <LuSearch className="text-lg absolute top-1/2 -translate-y-1/2 start-3"/>
                                    <input type="text" className="form-input w-full text-[15px] ps-10 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" name="s" id="searchItem2" placeholder="Search..." />
                                </div>

                                <h5 className="text-lg font-semibold mt-6">Collection Type</h5>

                                <GalleryItems2 allData={true} pagination={true}/>
                            </div>

            </div>

            <div className="lg:w-2/5 md:w-1/2 md:ps-4">
                            <div className="sticky top-20">
                                <h5 className="text-lg font-semibold">Filter by</h5>

                                <div className="form-icon relative mt-4">
                                    <LuSearch className="text-lg absolute top-1/2 -translate-y-1/2 start-3"/>
                                    <input type="text" className="form-input w-full text-[15px] ps-10 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0" name="s" id="searchItem2" placeholder="Search..." />
                                </div>

                                <h5 className="text-lg font-semibold mt-6">Collection Type</h5>

                                <GalleryItems allData={true} pagination={true}/>
                            </div>
                        </div>
                </div>
            </div>
            
            </section>
            
        </div>
            <Footer />
        </>
    )
}
