import React, { useState,useEffect } from 'react'
import metamask from '../assets/images/wallet/metamask.png';
import alpha from '../assets/images/wallet/alpha.png';
import binance from '../assets/images/wallet/binance.png';
import bitski from '../assets/images/wallet/bitski.png';
import coinbase from '../assets/images/wallet/coinbase.png';
import enjin from '../assets/images/wallet/enjin.png';
import formatic from '../assets/images/wallet/formatic.png';
import pionex from '../assets/images/wallet/pionex.png';
import torus from '../assets/images/wallet/torus.png';
import trustwallet from '../assets/images/wallet/trustwallet.png';
import connect from '../assets/images/wallet/wallet-connect.png';
import Navbar from '../components/navbar'
import Footer from '../components/footer';
import Switcher from '../components/switcher';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import testimonialIcon from '@iconify/icons-dashicons/testimonial';
import snippetsOutlined from '@iconify/icons-ant-design/snippets-outlined';
import webSectionAlt from '@iconify/icons-uil/web-section-alt';
import Testimonial from '../components/Testimonial'
import {MdOutlineArrowForward,RxQuestionMarkCircled, PiBookBookmarkBold, HiOutlineCog6Tooth} from "../assets/icons/vander"



export default function AIMarket() {
    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);
    const herbal = 'https://cdnl.iconscout.com/lottie/premium/thumb/herbal-medicine-5650957-4709606.gif'
    const handyman = 'https://www.gregschwem.com/wp/wp-content/uploads/2013/07/cartoon+gif+handyman.gif'
    const pettrainer = 'https://i.pinimg.com/originals/77/12/cf/7712cfb76c758858b5240ff8111759e8.gif'
    const culinary = 'https://i.pinimg.com/originals/69/b1/e4/69b1e4d026d26545711d93427247350e.gif'
    const organic = 'https://i.pinimg.com/originals/23/9d/46/239d469ad492a0ce43af5913af26a4be.gif'
    const lawyer = 'https://media0.giphy.com/media/5gXaLABebtK5YDw8Mp/giphy.gif'
    const accountant = 'https://gifdb.com/images/high/accounting-aesthetic-anime-4xwumjdnvlpre5jn.gif'
    const chef = 'https://i.pinimg.com/originals/77/bd/95/77bd9523d3332fd28e3e0baf341a95b8.gif'
    const waterguy = 'https://gifdb.com/images/high/backstroke-swimming-animation-m3dkaaq5nx89ug11.gif'
    const tatoo = 'https://cdn.dribbble.com/users/230198/screenshots/4131685/tattoo_800x600.gif'
    const engineer = 'https://cdn.dribbble.com/users/1099530/screenshots/2976077/pu_20small-0.gif'
    const teacher = 'https://i.pinimg.com/originals/9f/05/89/9f0589e96e0355fb59046beb18ab278f.gif'
    const fisherman = 'https://cdn.dribbble.com/users/1422578/screenshots/3969495/fisherman.gif'
    const hunter = 'https://cdn.dribbble.com/users/1492584/screenshots/6050815/video_801.gif'
    const investor = 'https://media.licdn.com/dms/image/D4D12AQGZhe9oisJCVw/article-cover_image-shrink_600_2000/0/1696303724544?e=2147483647&v=beta&t=6GusGlVPxuv2iQ8JfL3KgR8gR8bcCcdYcbyvT-2m4Yw'
    const diet = 'https://static01.nyt.com/images/2016/08/18/health/well_nutrition/well_nutrition-jumbo-v3.gif'
    const barista = 'https://i.pinimg.com/originals/72/22/64/722264317bb747b1d3ecab8be31f36f2.gif'
    const preacher = 'https://i.gifer.com/9aom.gif'
    const landscaper = 'https://mir-s3-cdn-cf.behance.net/project_modules/disp/84956343643817.6247ed60d10ed.gif'
    const travel = 'https://media1.giphy.com/media/3orf6bJ8jDP2Nl1TOw/200w.gif?cid=6c09b952fusyini5qkm45g4m8ume6u9qh4jvjwptvrlz6hw0&ep=v1_videos_search&rid=200w.gif&ct=v'
    const task = 'https://user-images.githubusercontent.com/74038190/236119160-976a0405-caa7-470c-9356-16d43402ea0a.gif'
    const legal = 'https://cdn.dribbble.com/users/2921021/screenshots/10638851/fun_animation.gif'
    const bifin = 'https://media3.giphy.com/media/ADD4w6XgqLBJohQdBK/200w.gif?cid=6c09b952fc7ukxzjicuggan6r479canugkpeyhttfou9czd9&ep=v1_gifs_search&rid=200w.gif&ct=g'
    const tutor = 'https://i.pinimg.com/originals/bb/0c/c7/bb0cc783196fa9b2119864ff90eb5702.gif'
    const mindease = 'https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F8165700a-735a-41a6-bec3-284690b966f0_480x480.gif'
    const local = 'https://miro.medium.com/v2/resize:fit:1400/1*xHIWMbzPsZnU3eQtTCnYog.gif'
    const librarian = 'https://cdn.dribbble.com/users/404685/screenshots/3986849/booksshot.gif'
    const fashion = 'https://cdn.dribbble.com/users/1019864/screenshots/9189989/blue_dribbble.gif'
    const marketing = 'https://cdn.dribbble.com/users/1894420/screenshots/14081921/media/830e4e7bfa67b2ab8142a42cd456914c.gif'
    const housekeeper = 'https://cdn.dribbble.com/users/209133/screenshots/2057188/julie_mopping_small.gif'
    // const greentumb = 'https://cdn.dribbble.com/users/31818/screenshots/11211447/thumbsup-dribb.gif'
    // const content = 'https://visme.co/blog/wp-content/uploads/2020/02/header-1200.gif'
    // const taco = 'https://i.pinimg.com/originals/d0/cc/a7/d0cca7105c41eb28826b4381d56258b4.gif'
    // const signestimator = 'https://cdn.dribbble.com/users/2004171/screenshots/5646149/dribbble_canvas__calculator_.gif'
    // const signregulator = 'https://miro.medium.com/v2/resize:fit:960/0*az0hftqzLKSEHgj4'
    // const signdesign = 'https://i.pinimg.com/originals/44/be/24/44be2433f06f6b0d5660203727d6e2ef.gif'
    // const entertainmentadvicer = 'https://cdn.dribbble.com/users/981132/screenshots/12032379/media/be507e70145a64ec5fa73ee20afd5469.gif'
    // const betterparent = 'https://cdn.dribbble.com/users/499505/screenshots/3735156/parents-400_300.gif'
    // const eco = 'https://i.pinimg.com/originals/6a/2a/49/6a2a490fa979f32f6337212b18e65e1d.gif'
    // const languagecoach = 'https://cdn.dribbble.com/users/3532751/screenshots/7082068/media/6db6da53fa6475a9274f71c6d33605a5.gif'
    // const mastergrower = 'https://qph.cf2.quoracdn.net/main-qimg-0a762740467883c4f579fa5b70543703'
    // const doctor = 'https://i.pinimg.com/originals/b4/3d/b7/b43db78f64c8e26fb580bb7f00b66222.gif'


    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }

    const walletData = [
        {
            title: 'Handyman Pro',
            image: handyman,
            link:'https://chat.openai.com/g/g-E3egcBmQp-handyman-pro',
        },
        {
            title: 'Herbal Medicine Guide',
            image: herbal,
            link:'https://chat.openai.com/g/g-r2wyAPcDC-herbal-medicine-guide',
        },
        {
            title: 'Culinary Creator',
            image: culinary,
            link:'https://chat.openai.com/g/g-nBhDDsLdH-culinary-creator',
        },
        {
            title: 'Pet Trainer Pro',
            image: pettrainer,
            link:'https://chat.openai.com/g/g-SrXs2C3c7-pet-trainer-pro',
        },
        {
            title: 'Organic Guardan Guide',
            image: organic,
            link:"https://chat.openai.com/g/g-IqGTkdI85-organic-garden-guide",
        },
        {
            title: 'The Lawyer',
            image: lawyer,
            link:'https://chat.openai.com/g/g-3vlgwIKJU-the-lawyer',
        },
        {
            title: 'The Accountant',
            image: accountant,
            link:'https://chat.openai.com/g/g-CpztT44Iw-the-accountant',
        },
        {
            title: 'The Chef',
            image: chef,
            link:'https://chat.openai.com/g/g-oSyAi6n9c-the-chef',
        },
        {
            title: 'The Water Guy',
            image: waterguy,
            link:'https://chat.openai.com/g/g-Hp0NBcVbV-the-water-guy',
        },
        {
            title: 'The Tattoo Artist',
            image: tatoo,
            link:'https://chat.openai.com/g/g-Al25TxUFm-the-tattoo-artist',
        },
        {
            title: 'The Engineer',
            image: engineer,
            link:'https://chat.openai.com/g/g-ywyFytEtc-the-engineer',
        },
        {
            title: 'The Teacher',
            image: teacher,
            link:'https://chat.openai.com/g/g-nZ9cuDM2b-the-teacher',
        },
        {
            title: 'The Fisherman',
            image: fisherman,
            link:'https://chat.openai.com/g/g-pRnUvzThB-the-fisherman',
        },
        {
            title: 'The Hunter',
            image: hunter,
            link:'https://chat.openai.com/g/g-UL8vu6I97-the-hunter',
        },
        {
            title: 'The Investor',
            image: investor,
            link:'https://chat.openai.com/g/g-b3DvPEItX-the-investor',
        },
        {
            title: 'The Dietician',
            image: diet,
            link:'https://chat.openai.com/g/g-yoklWP5lL-the-dietician',
        },
        {
            title: 'The Barista',
            image: barista,
            link:'https://chat.openai.com/g/g-9DMXwmIn4-the-barista',
        },
        {
            title: 'The Preacher',
            image: preacher,
            link:'https://chat.openai.com/g/g-ri1OGmLbT-the-preacher',
        },
        {
            title: 'The Landscaper',
            image: landscaper,
            link:'https://chat.openai.com/g/g-LrKW56ZwT-the-landscaper',
        },
        {
            title: 'The Travel Guide',
            image: travel,
            link:'https://chat.openai.com/g/g-oJSY39Ekf-the-travel-guide',
        },
        {
            title: 'Task Master',
            image: task,
            link:'https://chat.openai.com/g/g-t816q5f7r-task-master',
        },
        {
            title: 'Legal Gpt',
            image: legal,
            link:'https://chat.openai.com/g/g-bTmiKZzXi-legal-gpt',
        },
        {
            title: 'BizFin GPT',
            image: bifin,
            link:'https://chat.openai.com/g/g-B2qWcdiIR-bizfin-gpt',
        },
        {
            title: 'The Tutor',
            image: tutor,
            link:'https://chat.openai.com/g/g-F647NmpXg-the-tutor',
        },
        {
            title: 'Mind Ease',
            image: mindease,
            link:'https://chat.openai.com/g/g-5WiGFzSCG-mind-ease',
        },
        {
            title: 'Local GPT',
            image: local,
            link:'https://chat.openai.com/g/g-V2ubxiU6q-local-gpt',
        },
        {
            title: 'The Librarian',
            image: librarian,
            link:'https://chat.openai.com/g/g-j3qh1bgjm-the-librarian',
        },
        {
            title: 'The Fashion Advisor',
            image: fashion,
            link:'https://chat.openai.com/g/g-3J8ViqkmK-the-fashion-advisor',
        },
        {
            title: 'The Marketing Wizard',
            image: marketing,
            link:'https://chat.openai.com/g/g-4Xo9Ph3Ph-the-marketing-wizard',
        },
        {
            title: 'The Housekeeper',
            image: housekeeper,
            link:'https://chat.openai.com/g/g-BWsweZFP5-the-housekeeper',
        },
        // {
        //     title: 'The Green Thumb',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-UtJqwpEm9-the-green-thumb',
        // },
        // {
        //     title: 'The Content Publisher',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-0NnW8YObl-the-content-publisher',
        // },
        // {
        //     title: 'Taco Tuesday',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-PG1O4CRFk-taco-tuesday',
        // },
        // {
        //     title: 'The Sign Estimator',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-48WM1i4oM-the-sign-estimator',
        // },
        // {
        //     title: 'The Sign Regulator',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-tzvrtPKDz-the-sign-regulator',
        // },
        // {
        //     title: 'Sign Design',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-KeMTw8LuY-sign-design',
        // },
        // {
        //     title: 'The Entertainment Advisor',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-7zxxff2hb-the-entertainment-advisor',
        // },
        // {
        //     title: 'The Better Parent',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-yENKPsYEg-the-better-parent',
        // },
        // {
        //     title: 'The Eco Life',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-JGI7DPqlL-the-eco-life',
        // },
        // {
        //     title: 'The Language Coach',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-UzIdDKfCk-the-language-coach',
        // },
        // {
        //     title: 'The Master Grower',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-6O5SgPM6U-the-master-grower',
        // },
        // {
        //     title: 'The Doctor',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-pObti89zM-the-doctor',
        // },
        // {
        //     title: 'The Foodie',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-MmNZWcuph-the-foodie',
        // },
        // {
        //     title: 'The Taco Truck',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-sV02btR4I-the-taco-truck',
        // },
        // {
        //     title: 'Sign Marketing GPT',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ZPzheprB2-sign-marketing-gpt',
        // },
        // {
        //     title: 'The Composer',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-QXxv9ov6s-the-composer',
        // },
        // {
        //     title: 'The Writer',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-v91zLF42n-the-writer',
        // },
        // {
        //     title: 'The Golf Pro',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-HoVEjWnr7-the-golf-pro',
        // },
        // {
        //     title: 'The Winery',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-Y7eOjbh3y-the-winery',
        // },
        // {
        //     title: 'The Football Coach',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-CEURppiAA-the-football-coach',
        // },
        // {
        //     title: 'The Soccer Coach',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-DDgboJs8z-the-soccer-coach',
        // },
        // {
        //     title: 'The Allsports Coach',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-pH2oL51rJ-the-allsports-coach',
        // },
        // {
        //     title: 'Food Truck Finder',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-H5sJJ14AU-food-truck-finder',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'Chess Master',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-jo6bM88mb-chessmaster',
        // },
        // {
        //     title: 'Prompt Creator',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-6zlzo8pO3-prompt-creator',
        // },
        // {
        //     title: 'The Gambler',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-G4lqNI0S4-the-gambler',
        // },
        // {
        //     title: 'The Personal Trainer',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-gSyqP4nEF-the-personal-trainer',
        // },
        // {
        //     title: 'Permit Pro Assistant',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-MsTIS195b-permit-pro-assistant',
        // },
        // {
        //     title: 'Voice GPT',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-g3XFTdSGB-voice-gpt',
        // },
        // {
        //     title: 'Content Creation Wizard',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-1jJzWWJOK-content-creation-wizard',
        // },
        // {
        //     title: 'SmokeFree',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-DrNN4mhT4-smokefree',
        // },
        // {
        //     title: 'GPT Builder',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-mgnAH6h9W-gpt-builder',
        // },
        // {
        //     title: 'Language Link',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-iL5Q1t2yD-language-link',
        // },
        // {
        //     title: 'Ethical Insight',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-A6B8rY3f6-ethical-insight',
        // },
        // {
        //     title: 'Narrative Weaver',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-Zn1AxSKAA-narrative-weaver',
        // },
        // {
        //     title: 'EcoGPT',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-8E2VSxhKJ-ecogpt',
        // },
        // {
        //     title: 'Expertise GPT',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-wi8SAV3wj-expertise-gpt',
        // },
        // {
        //     title: 'Cultural Translations',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-yT0fkfoeQ-cultural-translations',
        // },
        // {
        //     title: 'Empathia',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-4teXLlKF0-empathia',
        // },
        // {
        //     title: 'HistAnalytica GPT',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-Brip7z2TA-histanalytica-gpt',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        // {
        //     title: 'The Weatherman',
        //     image: waterguy,
        //     link:'https://chat.openai.com/g/g-ByfBk2g8B-the-weatherman',
        // },
        
    ]

    const spanStyle = {
        background: `linear-gradient(90deg, #fe3499, #ffffff, #fb53a4)`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        display: 'inline-block',
    };
    return (
        <>
            <section className="relative md:py-24 py-16" >
                <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold"><span className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold" style={spanStyle}>Our GPT</span> Marketplace</h3>
                </div>
                <div className="container" style={{ height: '500px', overflowY: 'auto', scrollbarWidth: 'thin', scrollbarColor: '#e6e6e6 #1e293b',}}>
                    <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
                        {
                            walletData.map((ele, index) => (
                                <Link to={ele.link} style={{cursor:'pointer',marginBottom:'16px'}}>
                                <div key={index} className="group relative rounded-xl bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 p-6 text-center mt-10 hover:shadow-md dark:hover:shadow-gray-800 transition-all duration-500">
                                    <div className="relative -mt-16">
                                        <img src={ele.image} className="bg-white dark:bg-slate-900 h-20 w-20 rounded-full shadow-md dark:shadow-gray-800 mx-auto p-3" alt="" />
                                        <h5 className="text-lg font-semibold mt-5">{ele.title}</h5>
                                        {/* <p className="text-slate-400 mt-3">{ele.content}</p> */}
                                    </div>
                                </div>
                                </Link>
                            ))
                        }
                    </div>
                </div>
            </section>

            {/* <div className="container-fluid lg:px-10 md:px-3 relative overflow-hidden">
                <div className="lg:py-24 py-[74px] md:rounded-lg shadow dark:shadow-gray-800 bg-violet-700/10 dark:bg-violet-600/20" style={{backgroundImage:`url(${handyman})`,backgroundSize: 'cover', backgroundPosition: 'center',}}>
                    <div className="container" >
                        <div className="grid grid-cols-1 text-center" >               
                           <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-4xl mb-4">Sample Testimonial</h1>
                           <p className="lg:leading-snug leading-snug text-2xl lg:text-2xl mb-4">Joy's visual representation of AI models as NFTs is innovative and engaging</p>
                      </div>
                  </div>
                </div>
            </div> */}

                {/* <div className="container">

                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 mt-6 gap-[30px]">

                        <div className="text-center px-6 mt-6">
                            <div className="w-20 h-20 bg-violet-600/5 text-violet-600 rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                <div style={{color:'white'}}><PiBookBookmarkBold/></div>
                            </div>

                            <div className="content mt-7">
                                <Link to="#" className="title h5 text-lg font-semibold hover:text-violet-600">Call-to-Action</Link>
                                <p className="text-slate-400 mt-3">Join Our Sustainable LOL DAO - Shape the future of AI handyman</p>

                            </div>
                        </div>

                        <div className="text-center px-6 mt-6">
                            <div className="w-20 h-20 bg-violet-600/5 text-violet-600 rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                <div style={{color:'white'}}><Icon icon={snippetsOutlined} /></div>
                            </div>

                            <div className="content mt-7">
                                <Link to="#" className="title h5 text-lg font-semibold hover:text-violet-600">Snippet</Link>
                                <p className="text-slate-400 mt-3">Learn more about AI, NFTs, and lawyer with our resources</p>
                            </div>
                        </div>

                        <div className="text-center px-6 mt-6">
                            <div className="w-20 h-20 bg-violet-600/5 text-violet-600 rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                <div style={{color:'white'}}><Icon icon={webSectionAlt} /></div>
                            </div>

                            <div className="content mt-7">
                                <Link to="#" className="title h5 text-lg font-semibold hover:text-violet-600">Section</Link>
                                <p className="text-slate-400 mt-3">Stay informed about the latest in AI and digital artistry</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-24">
                    <Testimonial/>
                </div> */}
            {/* <div className="container mt-24">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold"><span className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold" style={spanStyle}>How Does</span> It Work ?</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Embark on your visual AI innovation journey with Joy – where selection, handyman, and creation converge in a harmonious symphony of technology and artistry.</p>
                    </div>

                    <div className="flex justify-center mt-8">
                        <div className="lg:w-2/3">

                            {accordionData.map((item, index) => (
                                <div key={index} className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                    <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                        <button onClick={() => toggleAccordion(index)} type="button"
                                            className={`flex justify-between items-center p-5 w-full font-semibold text-start ${activeIndex === index ? 'bg-gray-50 dark:bg-slate-800 text-violet-600' : ''}`}
                                            data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                            <span>{item.title}</span>
                                            <svg data-accordion-icon className={`w-4 h-4  shrink-0 ${activeIndex === index ? "rotate-180" : "rotate-360"}`}  fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                            </svg>
                                        </button>
                                    </h2>
                                    {activeIndex === index && (
                                        <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                            <div className="p-5">
                                                <p className="text-slate-400 dark:text-gray-400">{item.content}</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
        </>
    )
}
