import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import logo_icon_50 from '../assets/images/logo-icon-50.png';
import logo_dark from '../assets/images/logo-dark.png';
import logo_white from '../assets/images/logo-white.png';
import { LuSearch, PiWalletBold, AiOutlineCopy, AiOutlineUser, LuSettings, LiaSignOutAltSolid } from "../assets/icons/vander";

export default function Navbar() {
    const [isDropdown, openDropdown] = useState(false);
    const [isOpen, setMenu] = useState(false);
    const image = 'https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/UUID-cl8j7kpbw30430kxqy4a2kw1yt/public';

    useEffect(() => {
        activateMenu();
        window.addEventListener("scroll", windowScroll);
        return () => {
            window.removeEventListener("scroll", windowScroll);
        };
    }, []);

    function windowScroll() {
        const navbar = document.getElementById("topnav");
        if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
            navbar?.classList.add("nav-sticky");
        } else {
            navbar?.classList.remove("nav-sticky");
        }

        const mybutton = document.getElementById("back-to-top");
        if (mybutton != null) {
            if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                mybutton.classList.add("flex");
                mybutton.classList.remove("hidden");
            } else {
                mybutton.classList.add("hidden");
                mybutton.classList.remove("flex");
            }
        }
    }

    const toggleMenu = () => {
        setMenu(!isOpen);
    };

    const getClosest = (elem, selector) => {
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) { }
                    return i > -1;
                };
        }

        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.matches(selector)) return elem;
        }
        return null;
    };

    const activateMenu = () => {
        var menuItems = document.getElementsByClassName("sub-menu-item");
        if (menuItems) {
            var matchingMenuItem = null;
            for (var idx = 0; idx < menuItems.length; idx++) {
                if (menuItems[idx].href === window.location.href) {
                    matchingMenuItem = menuItems[idx];
                }
            }

            if (matchingMenuItem) {
                matchingMenuItem.classList.add('active');

                var immediateParent = getClosest(matchingMenuItem, 'li');
                if (immediateParent) {
                    immediateParent.classList.add('active');
                }

                var parent = getClosest(immediateParent, '.child-menu-item');
                if (parent) {
                    parent.classList.add('active');
                }

                var parent = getClosest(parent || immediateParent, '.parent-menu-item');
                if (parent) {
                    parent.classList.add('active');
                    var parentMenuitem = parent.querySelector('.menu-item');
                    if (parentMenuitem) {
                        parentMenuitem.classList.add('active');
                    }
                    var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                } else {
                    var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                }
            }
        }
    };

    const spanStyle = {
        background: `linear-gradient(90deg, #fe3499, #f8a8c6, #fb53a4)`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        display: 'inline-block',
    };

    return (
        <>
            <nav id="topnav" className="defaultscroll is-sticky">
                <div className="container">
                    <Link className="logo ps-0" to="/">
                        <img src={logo_icon_50} className="inline-block sm:hidden" alt="" />
                        <div className="sm:block hidden">
                            <img src={logo_dark} className="inline-block dark:hidden h-7" alt="" />
                            <img height={'3.75rem !important'} width={70} src={logo_white} className="hidden dark:inline-block h-77" alt="" />
                        </div>
                    </Link>

                    <ul className="buy-button list-none mb-0">
                        {/* Commented sections for future use */}
                    </ul>

                    <nav role='navigation'>
                        <div id="menuToggle">
                            <input type="checkbox" checked={isOpen} onChange={toggleMenu} />
                            <span></span>
                            <span></span>
                            <span></span>
                            {isOpen && (
                                <ul id="menu" className={isOpen ? 'open' : ''}>
                                    <li className="has-submenu parent-menu-item"><Link to="/">Home</Link></li>
                                    <li><Link to="/about-us" className="sub-menu-item-about">About Us</Link></li>
                                    <li><Link to="/AI-Marketplace" className="sub-menu-item-about">AI Marketplace</Link></li>
                                    <li>
                                        <a href="https://loldao.com" target="_blank" rel="noopener noreferrer" className="sub-menu-item-about">
                                            AI Models Marketplace
                                        </a>
                                    </li>
                                    <li><Link to="/NFT-Gallery" className="sub-menu-item-about">NFT Gallery</Link></li>
                                    <li><Link to="/Community-DAO" className="sub-menu-item-about">Community & DAO</Link></li>
                                    <li><Link to="/How-It-Works" className="sub-menu-item-about">How It Works</Link></li>
                                    <li><Link to="/Training-Module" className="sub-menu-item-about">Training Module</Link></li>
                                    <li><Link to="/Education-Resource" className="sub-menu-item-about">Educational Resources</Link></li>
                                    <li className="has-submenu parent-parent-menu-item"><Link to="/Support-FAQ">Support & FAQs</Link></li>
                                    <li><Link to="/Blogs-News" className="sub-menu-item-about">Blog/News</Link></li>
                                    <li><Link to="/contact-us" className="sub-menu-item-about">Register</Link></li>
                                    <li><Link to="/Legal-Privacy" className="sub-menu-item-about">Legal & Privacy</Link></li>
                                    <li><Link to="/createNFT" className="sub-menu-item-create">User Dashboard</Link></li>
                                </ul>
                            )}
                        </div>
                    </nav>
                </div>
            </nav>
        </>
    );
}
