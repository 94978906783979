import React, { useEffect } from 'react'
import TinySlider from "tiny-slider-react";
import { Link } from 'react-router-dom';
import { data } from '../../data/data';
import Navbar from '../../components/navbar'
import CreatorThree from '../../components/creator-three';
import Feature from '../../components/feature';
import Blog from '../../components/blog';
import GetTouch from '../../components/get-touch';
import Footer from '../../components/footer';
import ExploreTtem from '../../components/explore-item';
import Switcher from '../../components/switcher';
import img from '../../../src/assets/images/AI_6.png';
import img1 from '../../assets/images/AI_ROBO_.png';
import LOLDAO from '../../assets/images/lol-new-img.png'




// img = 'https://t4.ftcdn.net/jpg/02/10/96/95/360_F_210969565_cIHkcrIzRpWNZzq8eaQnYotG4pkHh0P9.jpg';

const settings = {
    items: 1,
    controls: true,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    nav: false,
    speed: 400,
    gutter: 0,
    controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>']
};
export default function AboutUs() {
    useEffect(() => {
        document.documentElement.classList.add('dark');
    });

    const spanStyle = {
        background: `linear-gradient(90deg, #fe3499, #f8a8c6, #fb53a4)`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        display: 'inline-block',
    };
   
    return (
        <>
            <Navbar />

            <section className="relative table w-full py-36 bg-no-repeat" style={{backgroundImage:`url(${img1})`,backgroundSize: 'cover', backgroundPosition: 'center'}}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10 glassmorphic6">
                    <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4"><span style={spanStyle}>Who </span> We Are ?</h4>
                    <p className="text-slate-400 max-w-xl mx-auto">At Joy Marketplace, we believe that living sustainably should be enjoyable and accessible to everyone. Our platform connects you with a wide range of eco-friendly products, services, and experiences, empowering you to make choices that benefit both the planet and your well-being.</p>
                        <div className="mt-4" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                    <img src={LOLDAO} style={{ width: '120px', height: 'auto' }} alt='LOL' />
                  </div>
                  <div className="mt-2" style={{marginLeft: '13px', display: 'flex', justifyContent: 'center', alignContent: 'center',fontSize:'16px' }}>
                    <p className="text-slate-400 max-w-xl mx-auto">DATA IS THE NEW OIL</p>
                  </div>
                        <div className="mt-6" style={{ marginLeft: '15px' }}>
                            <Link to="#" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full me-2 mt-2" style={{cursor:'pointer'}}>About Us</Link>
                        </div>

                    </div>
                    
                </div>

                
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
           
            <section className="relative md:py-24 py-16" style={{marginTop:'-190px'}}>


            {/* <div className="overflow-hidden after:content-[''] after:absolute after:h-10 after:w-10 after:bg-violet-600/10 dark:after:bg-violet-600/30 after:-top-[50px] after:start-[30%] after:-z-1 after:rounded-lg after:animate-[spin_10s_linear_infinite]"></div> */}
            
            {/* <span className="fixed blur-[200px] w-[600px] h-[600px] rounded-full top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 bg-gradient-to-tl from-red-600/20 to-violet-600/20 dark:from-red-600/40 dark:to-violet-600/40"></span> */}
            <div className="relative animate-[spin_30s_linear_infinite] -z-1">
                <span className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:h-2 after:w-8 after:rounded-md after:bg-violet-600/20 relative after:z-10"></span>
                <span className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:rotate-90 after:h-2 after:w-8 after:rounded-md after:bg-violet-600/20 relative after:z-10"></span>
            </div>

                <Feature />

                <div className="relative animate-[spin_30s_linear_infinite] -z-1">
                <span className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:h-2 after:w-8 after:rounded-md after:bg-violet-600/20 relative after:z-10"></span>
                <span className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:rotate-90 after:h-2 after:w-8 after:rounded-md after:bg-violet-600/20 relative after:z-10"></span>
            </div>
                
                
                {/* <ExploreTtem title="Explore Hot Items" />
                <Blog />
                <GetTouch /> */}
                <div className="relative animate-[spin_30s_linear_infinite] -z-1">
                <span className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:h-2 after:w-8 after:rounded-md after:bg-violet-600/20 relative after:z-10"></span>
                <span className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:rotate-90 after:h-2 after:w-8 after:rounded-md after:bg-violet-600/20 relative after:z-10"></span>
            </div>
            <div className="relative animate-[spin_30s_linear_infinite] -z-1">
                <span className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:h-2 after:w-8 after:rounded-md after:bg-violet-600/20 relative after:z-10"></span>
                <span className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:rotate-90 after:h-2 after:w-8 after:rounded-md after:bg-violet-600/20 relative after:z-10"></span>
            </div>
            </section>
            <Footer />
            {/* <Switcher /> */}
        </>
    )
}
