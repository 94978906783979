import React, { useState,useEffect } from 'react'
import metamask from '../assets/images/wallet/metamask.png';
import alpha from '../assets/images/wallet/alpha.png';
import binance from '../assets/images/wallet/binance.png';
import bitski from '../assets/images/wallet/bitski.png';
import coinbase from '../assets/images/wallet/coinbase.png';
import enjin from '../assets/images/wallet/enjin.png';
import formatic from '../assets/images/wallet/formatic.png';
import pionex from '../assets/images/wallet/pionex.png';
import torus from '../assets/images/wallet/torus.png';
import trustwallet from '../assets/images/wallet/trustwallet.png';
import connect from '../assets/images/wallet/wallet-connect.png';
import Navbar from '../components/navbar'
import Footer from '../components/footer';
import Switcher from '../components/switcher';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import testimonialIcon from '@iconify/icons-dashicons/testimonial';
import snippetsOutlined from '@iconify/icons-ant-design/snippets-outlined';
import webSectionAlt from '@iconify/icons-uil/web-section-alt';
import Testimonial from '../components/Testimonial'
import {MdOutlineArrowForward,RxQuestionMarkCircled, PiBookBookmarkBold, HiOutlineCog6Tooth} from "../assets/icons/vander"



export default function HomeSecond() {
    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);
    const IDT = 'https://i.pinimg.com/originals/f0/b9/ff/f0b9ff68aa5108b5c4550f1cbddceb03.gif'
    const trading = 'https://user-images.githubusercontent.com/115187902/230700872-d5f44b85-56c7-4e27-80a4-6e2db901e60c.gif'
    const copilot = 'https://i.pinimg.com/originals/92/99/40/929940c7800ad3868b0e7828d53073a7.gif'
    const community = 'https://i.pinimg.com/originals/bd/80/1c/bd801c722c6117f181d47783247b6111.gif'
    const art = 'https://cdn.shopify.com/s/files/1/0270/2671/8799/files/Untitled_design_1_480x480.gif?v=1652671358'
    const blockchain = 'https://media3.giphy.com/media/QnU6mOrBbElaIQz4Fe/giphy.gif'

    const accordionData = [
        {
            title: 'Select NFT',
            content: 'Dive into a curated selection of cutting-edge visual AI models offered by Joy. From image recognition to creative synthesis, choose the perfect AI companion for your innovation journey.'
        },
        {
            title: 'Trade NFT',
            content: 'Experience a seamless trading platform where you can exchange, buy, and sell AI models as NFTs. Join a dynamic marketplace that blends technology with the artistry of AI, creating a space where innovation meets investment.'
        },
        {
            title: 'Create NFT',
            content: "Unleash your creativity with Joy's intuitive tools. Craft unique and captivating visual experiences, pushing the boundaries of AI-driven artistry. Embark on a journey where your imagination meets the limitless possibilities of visual AI innovation."
        },
    ]
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }

    const walletData = [
        {
            title: 'Visual AI Model Trading',
            image: trading,
            content:'Trade AI models as visually appealing NFTs',
        },
        {
            title: 'Integrated Development Tools',
            image: IDT,
            content:'Seamlessly use tools like Figma and VS Code for development and customization.',
        },
        {
            title: 'Community-Driven Platform',
            image: community,
            content:'Collaborate and innovate in our vibrant community',
        },
        {
            title: 'Meet Your Copilot - AI Development Made Simple',
            image: copilot,
            content:'With Copilot, creating AI models and digital art is accessible to everyone. This AI assistant guides you through each step, making the process intuitive and enjoyable.',
        },
        {
            title: 'Discover Joy - Where AI Meets Art',
            image: art,
            content:"Joy redefines AI model trading through artistic NFT representation, blending technology with digital artistry in a community-driven space",
        },
        {
            title: 'Monetize Your Innovations with Blockchain',
            image: blockchain,
            content:'At Joy, blockchain ensures transparent and fair monetization for creators. Smart contracts automate royalty distributions, rewarding creators for their innovations.',
        },
        
    ]

    const spanStyle = {
        background: `linear-gradient(90deg, #fe3499, #ffffff, #fb53a4)`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        display: 'inline-block',
    };
    return (
        <>
            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
                        {
                            walletData.map((ele, index) => (
                                <div key={index} className="group relative rounded-xl bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 p-6 text-center mt-10 hover:shadow-md dark:hover:shadow-gray-800 transition-all duration-500">
                                    <div className="relative -mt-16">
                                        <img src={ele.image} className="bg-white dark:bg-slate-900 h-20 w-20 rounded-full shadow-md dark:shadow-gray-800 mx-auto p-3" alt="" />
                                        <h5 className="text-lg font-semibold mt-5">{ele.title}</h5>
                                        <p className="text-slate-400 mt-3">{ele.content}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>

            {/* <div className="container-fluid lg:px-10 md:px-3 relative overflow-hidden">
                <div className="lg:py-24 py-[74px] md:rounded-lg shadow dark:shadow-gray-800 bg-violet-700/10 dark:bg-violet-600/20" style={{backgroundImage:`url(${trading})`,backgroundSize: 'cover', backgroundPosition: 'center',}}>
                    <div className="container" >
                        <div className="grid grid-cols-1 text-center" >               
                           <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-4xl mb-4">Sample Testimonial</h1>
                           <p className="lg:leading-snug leading-snug text-2xl lg:text-2xl mb-4">Joy's visual representation of AI models as NFTs is innovative and engaging</p>
                      </div>
                  </div>
                </div>
            </div> */}

                <div className="container">

                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 mt-6 gap-[30px]">

                        <div className="text-center px-6 mt-6">
                            <div className="w-20 h-20 bg-violet-600/5 text-violet-600 rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                <div style={{color:'white'}}><PiBookBookmarkBold/></div>
                            </div>

                            <div className="content mt-7">
                                <Link to="#" className="title h5 text-lg font-semibold hover:text-violet-600">Call-to-Action</Link>
                                <p className="text-slate-400 mt-3">Join Our Sustainable LOL DAO - Shape the future of AI trading</p>

                            </div>
                        </div>

                        <div className="text-center px-6 mt-6">
                            <div className="w-20 h-20 bg-violet-600/5 text-violet-600 rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                <div style={{color:'white'}}><Icon icon={snippetsOutlined} /></div>
                            </div>

                            <div className="content mt-7">
                                <Link to="#" className="title h5 text-lg font-semibold hover:text-violet-600">Snippet</Link>
                                <p className="text-slate-400 mt-3">Learn more about AI, NFTs, and blockchain with our resources</p>
                            </div>
                        </div>

                        <div className="text-center px-6 mt-6">
                            <div className="w-20 h-20 bg-violet-600/5 text-violet-600 rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                <div style={{color:'white'}}><Icon icon={webSectionAlt} /></div>
                            </div>

                            <div className="content mt-7">
                                <Link to="#" className="title h5 text-lg font-semibold hover:text-violet-600">Section</Link>
                                <p className="text-slate-400 mt-3">Stay informed about the latest in AI and digital artistry</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-24">
                    <Testimonial/>
                </div>
            <div className="container mt-24">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold"><span className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold" style={spanStyle}>How Does</span> It Work ?</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Embark on your visual AI innovation journey with Joy – where selection, trading, and creation converge in a harmonious symphony of technology and artistry.</p>
                    </div>

                    <div className="flex justify-center mt-8">
                        <div className="lg:w-2/3">

                            {accordionData.map((item, index) => (
                                <div key={index} className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4">
                                    <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                        <button onClick={() => toggleAccordion(index)} type="button"
                                            className={`flex justify-between items-center p-5 w-full font-semibold text-start ${activeIndex === index ? 'bg-gray-50 dark:bg-slate-800 text-violet-600' : ''}`}
                                            data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
                                            <span>{item.title}</span>
                                            <svg data-accordion-icon className={`w-4 h-4  shrink-0 ${activeIndex === index ? "rotate-180" : "rotate-360"}`}  fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                            </svg>
                                        </button>
                                    </h2>
                                    {activeIndex === index && (
                                        <div id="accordion-collapse-body-1" aria-labelledby="accordion-collapse-heading-1">
                                            <div className="p-5">
                                                <p className="text-slate-400 dark:text-gray-400">{item.content}</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
        </>
    )
}
