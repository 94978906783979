import React, { useEffect,useState } from 'react'
import image from '../../assets/images/avatar/1.jpg';
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Switcher from '../../components/switcher';
import Select from 'react-select'
import Modal from 'react-modal';
import CreateNFTCards from '../../pages/createNFT-cards';
import { Link } from 'react-router-dom';
// import img1 from '../../assets/images/user-dashboard.png';
import {AiOutlineDashboard, PiBrowsers, AiOutlineSetting, IoMdLogOut} from "../../assets/icons/vander"
import { Facebook, Instagram, Linkedin, Youtube, Twitter } from 'react-feather';
import {LuClock, LuSearch, AiOutlineAppstore, AiOutlineStar} from "../../assets/icons/vander"
import LOLDAO from '../../assets/images/lol-new-img.png'

const options = [
    { value: 'Digital Art:', label: 'Digital Art:' },
    { value: 'Videos', label: 'Videos' },
    { value: 'Music', label: 'Music' },
    { value: 'Interactive Art', label: 'Interactive Art' },
    { value: 'AI Code', label: 'AI Code' },
];

const buyOptions = [
    { value: 'Deep Dream Art', label: 'Deep Dream Art' },
    { value: 'Neural Network Compositions', label: 'Neural Network Compositions' },
    { value: 'Deepfake Art', label: 'Deepfake Art' },
    { value: 'AI-Generated Game Assets', label: 'AI-Generated Game Assets' },
    { value: 'Processing Sketches', label: 'Processing Sketches' },
]


export default function UploadWork() {
    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);
    const handleChange = () => {
        const fileUploader = document.querySelector('#input-file');
        const getFile = fileUploader.files
        if (getFile.length !== 0) {
            const uploadedFile = getFile[0];
            readFile(uploadedFile);
        }
    }

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isHovered ? '#1e293b' : '#1e293b',
          
          
        }),
      };

    const readFile = (uploadedFile) => {
        if (uploadedFile) {
            const reader = new FileReader();
            reader.onload = () => {
                const parent = document.querySelector('.preview-box');
                parent.innerHTML = `<img class="preview-content" src=${reader.result} />`;
            };

            reader.readAsDataURL(uploadedFile);
        }
    };
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Function to toggle the modal visibility
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    useEffect(() => {
        document.documentElement.classList.add('dark');
    }, []);
    const spanStyle = {
        background: `linear-gradient(90deg, #fe3499, #f8a8c6, #fb53a4)`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        display: 'inline-block',
    };
    const img1 = 'https://lh7-us.googleusercontent.com/TBjm1xVMLVOyjkiwjrCZn4weRnwFwjh8XlaHe24jXguCzDTz0G7495HjamePYEeY-HJSJ7iY-pIIi8nt2te_e7szEhQg1vS1IJiodr362sq_EWtk0HQnvcM_dFaODnecoBDXHPcrDqDeUPXA3PLfNwU?auto=webp&format=pjpg&width=600'
    return (

        <>
            <Navbar />

            <section className="relative table w-full py-36 bg-no-repeat" style={{backgroundImage:`url(${img1})`,backgroundSize: 'cover', backgroundPosition: 'center'}}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                    <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4"><span style={spanStyle}>User </span> Dashboard</h4>
                    <p className="text-slate-400 max-w-xl mx-auto">Your Personal Joy Workspace</p>
                    <div className="mt-4" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                    <img src={LOLDAO} style={{ width: '120px', height: 'auto' }} alt='LOL' />
                  </div>
                  <div className="mt-2" style={{marginLeft: '13px', display: 'flex', justifyContent: 'center', alignContent: 'center',fontSize:'16px' }}>
                    <p className="text-slate-400 max-w-xl mx-auto">DATA IS THE NEW OIL</p>
                  </div>
                    </div>
                </div>

                
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            
            <section className="relative" style={{marginTop:'80px'}}>
                <div className="container z-1">
                    <div className="grid grid-cols-1">
                    <div className="lg:col-span-7 lg:ms-8">
                            <h5 className="md:text-3xl text-2xl font-semibold"><span style={spanStyle}>Manage Your </span>  Joy Experience</h5>
                            <p className="text-slate-400 mt-4">Your dashboard is the command center for your Joy activities. Easily manage your account settings, update your profile, and customize your preferences</p>
                            <div className="md:flex p-6 bg-gray-50 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700 mt-6">
                                
                                    <div className="flex items-center">
                                        <div className="ms-3">
                                            <Link to="#" className="font-bold text-2xl lg:text-3xl mb-3 text-violet-600 block hover:text-violet-600">Your Creations at a Glance</Link>
                                            <span className="text-slate-400 text-[16px] block mt-1">View and manage your AI models and NFTs. Track your uploads, trades, and view statistics on your creations' performance and popularity</span>
                                        </div>
                                    </div>
                                
                            </div>

                            <div className="md:flex p-6 bg-gray-50 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700 mt-6">
                                
                                    <div className="flex items-center">
                                        <div className="ms-3">
                                            <Link to="#" className="font-bold text-2xl lg:text-3xl mb-3 text-violet-600 block hover:text-violet-600">Engage with the Joy Community</Link>
                                            <span className="text-slate-400 text-[16px] block mt-1">Access DAO voting features, participate in community discussions, and stay updated with the latest collaborative projects right from your dashboard</span>
                                        </div>
                                    </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
            
            <div style={{marginTop:'-50px'}}>
                <CreateNFTCards />
            </div>
           

            <Footer />
            {/* <Switcher /> */}
        </>
    )
}
