import item1 from '../assets/images/items/1.jpg';
import item2 from '../assets/images/items/2.gif';
import item3 from '../assets/images/items/2.jpg';
import item4 from '../assets/images/items/3.jpg';
import item5 from '../assets/images/items/3.gif';
import item6 from '../assets/images/items/4.jpg';
import item7 from '../assets/images/items/5.jpg';
import item8 from '../assets/images/items/6.jpg';
import item9 from '../assets/images/items/8.jpg';
import item10 from '../assets/images/items/4.gif';
import item11 from '../assets/images/items/5.gif';
import item12 from '../assets/images/items/9.jpg';
import item13 from '../assets/images/items/1.gif';
import item14 from '../assets/images/items/20.jpg';
import item15 from '../assets/images/items/7.jpg';
import item16 from '../assets/images/items/10.jpg';
import item17 from '../assets/images/items/11.jpg';
import item18 from '../assets/images/items/12.jpg';
import item19 from '../assets/images/items/16.jpg';
import item22 from '../assets/images/items/14.jpg';
import item23 from '../assets/images/items/7.gif';
import item24 from '../assets/images/items/8.gif';
import item25 from '../assets/images/items/15.jpg';
import item26 from '../assets/images/items/17.jpg';
import item27 from '../assets/images/items/18.jpg';
import item28 from '../assets/images/items/19.jpg';

import image1 from '../assets/images/avatar/Ai-profile-1.png';
import image2 from '../assets/images/avatar/AI-Profile-2.png';
import image3 from '../assets/images/avatar/Ai-profile-3.png';
import image4 from '../assets/images/avatar/AI-profile-4.png';
import image5 from '../assets/images/avatar/ai-profile-5.png';
import image6 from '../assets/images/avatar/ai-profile-6.png';
import image7 from '../assets/images/avatar/ai-profile-7.png';
import image8 from '../assets/images/avatar/ai-profile-8.png';
import image9 from '../assets/images/avatar/ai-profile-9.png';
import image10 from '../assets/images/items/5.gif';
import image11 from '../assets/images/avatar/8.jpg';
import image12 from '../assets/images/items/2.gif';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/user-dashboard.png';
import blog3 from '../assets/images/blog/03.jpg';
import blog4 from '../assets/images/blog/04.jpg';
import blog5 from '../assets/images/blog/05.jpg';
import blog6 from '../assets/images/blog/06.jpg';
import blog7 from '../assets/images/blog/07.jpg';
import blog8 from '../assets/images/blog/08.jpg';
import blog9 from '../assets/images/blog/09.jpg';
import blog10 from '../assets/images/blog/10.jpg';
import blog11 from '../assets/images/blog/11.jpg';
import blog12 from '../assets/images/blog/12.jpg';

const nft1 = 'https://i.pinimg.com/originals/52/91/4b/52914b8ac2f16a11c42786c3d89a84f5.gif'
const nft2 = 'https://gifsec.com/wp-content/uploads/2022/12/nft-gif-2.gif'
const nft3 = 'https://dappradar.com/nft-metadata-image?encrypted=true&format=preview&filePath=5e4e378a3c9b6c22f5244079258b6e648273fc0af340a8c9208af1c69bb02c569ed77cf11ff6ae2f731e07013452c82b2b46368d0670287fb3206708ba0d915086c6c38540e7de3c47a830cf2cc6ac7a'
const nft4 = 'https://nftsolana.io/wp-content/uploads/2023/01/1-41.gif'
const nft5 = 'https://riseangle.com/storage/events/uqrss4Q6PysYcNwLVHvwIBFjLLi95yWM7wZmKXoR.gif'
const nft6 = 'https://assets-global.website-files.com/6241bcd9e666c1514401461d/629798f269567bc331d30a5c_milennabots-upcomingsolananft-nftmintradar%20(1).gif'
const nft7 = 'https://riseangle.com/storage/events/OCvuNIIIvpfPDQRqPz1o2n3hS0btxM8E9lZ2J345.gif'
const nft8 = 'https://i.pinimg.com/originals/e7/f5/00/e7f500f0ee861d58826836cc5e63a2ce.gif'



export const data = [
     {
        id:1,
        image: item1,
        subtext: '@ByteBrainiac',
        title: 'Genuine Undead #3902',
        avatar: image1,
        name:"Byte Art Brains",
        date: 'December 29, 2023 6:0:0',
        liveDate:'September 13, 2024 6:0:0',
        category: 'Games',
        subImage:[
            {
                index:1,
                image:item13,
            },
            {
                index:2,
                image:item3,
            },
            {
                index:3,
                image:item4,
            },
        ]
        },
        {
            id:2,
            image: item2,
            subtext: '@CanvasCraft',
            title: 'Windchime #768',
            avatar: image2,
            name:"Quantum Canvas",
            liveDate:'November 29, 2024 6:0:0',
             category: 'Games',
            subImage:[
                {
                    index:4,
                    image:item1,
                },
                {
                    index:5,
                    image:image12,
                },
                {
                    index:6,
                    image:item6,
                },
            ]
        },
        {
            id:3,
            image: item3,
            subtext: '@DigiMindForge',
            title: 'Probably A Label #3277',
            avatar: image3,
            name:"Digi Minds Forge",
            liveDate:'March 13, 2024 6:0:0',
            category: 'Art',
            subImage:[
                {
                    index:7,
                    image:item14,
                },
                {
                    index:8,
                    image:item13,
                },
                {
                    index:9,
                    image:item5,
                },
            ]
        }, {
            id:4,
            image: item4,
            subtext: '@PixelCreatorZone',
            title: 'Probably A Label #1711',
            avatar: image4,
            name:"Pixel Creations",
            date: 'January 13, 2024 5:3:1',
            liveDate:'May 6, 2024 6:0:0',
             category: 'Music',
            subImage:[
                {
                    index:10,
                    image:item7,
                },
                {
                    index:11,
                    image:item8,
                },
                {
                    index:12,
                    image:item5,
                },
            ]
        }, {
            id:5,
            image: item5,
            subtext: '@DreamWeaver',
            title: 'Shibuya Scramble Punks',
            avatar: image5,
            name:"Data Dream",
            liveDate:'July 19, 2024 6:0:0',
            category: 'Video',
            subImage:[
                {
                    index:13,
                    image:item15,
                },
                {
                    index:14,
                    image:item9,
                },
                {
                    index:15,
                    image:item12,
                },
            ]
        }, {
            id:6,
            image: item6,
            subtext: '@QuantumVisions',
            title: 'Probably A Label #650',
            avatar: image6,
            name:"Quantum Studios",
            liveDate:'July 20, 2024 6:0:0',
            category: 'Games',
            subImage:[
                {
                    index:16,
                    image:item10,
                },
                {
                    index:17,
                    image:item3,
                },
                {
                    index:18,
                    image:item11,
                },
            ]
        }, {
            id:7,
            image: item7,
            subtext: '@PixelCraftsman',
            title: 'Looki#0147',
            avatar: image7,
            name:"Pixel Craft",
            date: 'December 29, 2023 6:0:0',
            liveDate:'February 17, 2024 6:0:0',
            category: 'Games',
            subImage:[
                {
                    index:19,
                    image:item16,
                },
                {
                    index:20,
                    image:item17,
                },
                {
                    index:21,
                    image:item18,
                },
            ]
        }, {
            id:8,
            image: item8,
            subtext: '@CodeCreatorsHub',
            title: 'Poob #285',
            avatar: image8,
            name:"Code Creators",
            liveDate:'April 15, 2024 6:0:0',
            category: 'Art',
            subImage:[
                {
                    index:22,
                    image:item7,
                },
                {
                    index:24,
                    image:item9,
                },
                {
                    index:24,
                    image:item19,
                },
            ]
        },
        {
            id:9,
            image: item9,
            subtext: '@SynthArtisan',
            title: 'Umber Arrow',
            avatar: image9,
            name:"Crypto Synth",
            liveDate:'May 17, 2024 6:0:0',
            category: 'Music',
            subImage:[
                {
                    index:25,
                    image:item22,
                },
                {
                    index:26,
                    image:item23,
                },
                {
                    index:27,
                    image:item24,
                },
            ]
        },
        {
            id:10,
            image: item10,
            subtext: '@Princess',
            title: 'Gloam Druid',
            avatar: image10,
            name:"Rosaria Vargas",
            liveDate:'August 8, 2024 6:0:0',
             category: 'Memes',
            subImage:[
                {
                    index:28,
                    image:item25,
                },
                {
                    index:29,
                    image:item19,
                },
                {
                    index:30,
                    image:item26,
                },
            ]
        },
        {
            id:11,
            image: item11,
            subtext: '@PandaOne',
            title: 'Azuki #7421',
            avatar: image11,
            name:"Julius Canale",
            liveDate:'December 27, 2024 6:0:0',
             category: 'Memes',
            subImage:[
                {
                    index:31,
                    image:item27,
                },
                {
                    index:32,
                    image:item24,
                },
                {
                    index:33,
                    image:item28,
                },
            ]
        },
        {
            id:12,
            image: item12,
            subtext: '@FunnyGuy',
            title: 'Wolf-Cult Vanguard',
            avatar: image12,
            name:"Michael Williams",
            liveDate:'December 25, 2024 6:0:0',
            category: 'Music',
            subImage:[
                {
                    index:34,
                    image:item7,
                },
                {
                    index:35,
                    image:item14,
                },
                {
                    index:36,
                    image:item19,
                },
            ]
        },
]

export const data1 = [
    {
       id:1,
       image: item1,
       subtext: 'MikeWinkelmann',
       title: 'Genuine Undead #3902',
       avatar: image1,
       name:"Beeple ",
       date: 'December 29, 2023 6:0:0',
       liveDate:'September 13, 2024 6:0:0',
       category: 'Games',
       subImage:[
           {
               index:1,
               image:'item13',
               content:'"Joys visual representation of AI models as NFTs is innovative and engaging"',
           },
       ]
       },
       {
           id:2,
           image: item2,
           subtext: '@LarvaLabs',
           title: 'Windchime #768',
           avatar: image2,
           name:"CryptoPunks",
           liveDate:'November 29, 2024 6:0:0',
            category: 'Games',
            subImage:[
                {
                    index:4,
                    image:'item1',
                    content:'"Capturing the whimsical essence found in creative and imaginative NFT gif designs."',
               },
               
           ]
       },
       {
           id:3,
           image: item3,
           subtext: '@HashMasks',
           title: 'Probably A Label #3277',
           avatar: image3,
           name:"Hashmasks",
           liveDate:'March 13, 2024 6:0:0',
           category: 'Art',
           subImage:[
               {
                   index:7,
                   image:'item14',
                   content:'"Reflecting the dynamic nature of animated NFTs, captivating attention through variation"',

               },
               
           ]
       }, {
           id:4,
           image: item4,
           subtext: '@PixelCreatorZone',
           title: 'Probably A Label #1711',
           avatar: image4,
           name:"Pixel Creations",
           date: 'January 13, 2024 5:3:1',
           liveDate:'May 6, 2024 6:0:0',
            category: 'Music',
           subImage:[
               {
                   index:10,
                   image:item7,
               },
               {
                   index:11,
                   image:item8,
               },
               {
                   index:12,
                   image:item5,
               },
           ]
       }, {
           id:5,
           image: item5,
           subtext: '@DreamWeaver',
           title: 'Shibuya Scramble Punks',
           avatar: image5,
           name:"Data Dream",
           liveDate:'July 19, 2024 6:0:0',
           category: 'Video',
           subImage:[
               {
                   index:13,
                   image:item15,
               },
               {
                   index:14,
                   image:item9,
               },
               {
                   index:15,
                   image:item12,
               },
           ]
       }, {
           id:6,
           image: item6,
           subtext: '@QuantumVisions',
           title: 'Probably A Label #650',
           avatar: image6,
           name:"Quantum Studios",
           liveDate:'July 20, 2024 6:0:0',
           category: 'Games',
           subImage:[
               {
                   index:16,
                   image:item10,
               },
               {
                   index:17,
                   image:item3,
               },
               {
                   index:18,
                   image:item11,
               },
           ]
       }, {
           id:7,
           image: item7,
           subtext: '@PixelCraftsman',
           title: 'Looki#0147',
           avatar: image7,
           name:"Pixel Craft",
           date: 'December 29, 2023 6:0:0',
           liveDate:'February 17, 2024 6:0:0',
           category: 'Games',
           subImage:[
               {
                   index:19,
                   image:item16,
               },
               {
                   index:20,
                   image:item17,
               },
               {
                   index:21,
                   image:item18,
               },
           ]
       }, {
           id:8,
           image: item8,
           subtext: '@CodeCreatorsHub',
           title: 'Poob #285',
           avatar: image8,
           name:"Code Creators",
           liveDate:'April 15, 2024 6:0:0',
           category: 'Art',
           subImage:[
               {
                   index:22,
                   image:item7,
               },
               {
                   index:24,
                   image:item9,
               },
               {
                   index:24,
                   image:item19,
               },
           ]
       },
       {
           id:9,
           image: item9,
           subtext: '@SynthArtisan',
           title: 'Umber Arrow',
           avatar: image9,
           name:"Crypto Synth",
           liveDate:'May 17, 2024 6:0:0',
           category: 'Music',
           subImage:[
               {
                   index:25,
                   image:item22,
               },
               {
                   index:26,
                   image:item23,
               },
               {
                   index:27,
                   image:item24,
               },
           ]
       },
       {
           id:10,
           image: item10,
           subtext: '@Princess',
           title: 'Gloam Druid',
           avatar: image10,
           name:"Rosaria Vargas",
           liveDate:'August 8, 2024 6:0:0',
            category: 'Memes',
           subImage:[
               {
                   index:28,
                   image:item25,
               },
               {
                   index:29,
                   image:item19,
               },
               {
                   index:30,
                   image:item26,
               },
           ]
       },
       {
           id:11,
           image: item11,
           subtext: '@PandaOne',
           title: 'Azuki #7421',
           avatar: image11,
           name:"Julius Canale",
           liveDate:'December 27, 2024 6:0:0',
            category: 'Memes',
           subImage:[
               {
                   index:31,
                   image:item27,
               },
               {
                   index:32,
                   image:item24,
               },
               {
                   index:33,
                   image:item28,
               },
           ]
       },
       {
           id:12,
           image: item12,
           subtext: '@FunnyGuy',
           title: 'Wolf-Cult Vanguard',
           avatar: image12,
           name:"Michael Williams",
           liveDate:'December 25, 2024 6:0:0',
           category: 'Music',
           subImage:[
               {
                   index:34,
                   image:item7,
               },
               {
                   index:35,
                   image:item14,
               },
               {
                   index:36,
                   image:item19,
               },
           ]
       },
]

export const data3 = [
    {
       id:1,
       image: nft6,
       subtext: 'MikeWinkelmann',
       title: 'Genuine Undead #3902',
       avatar: nft6,
       name:"Beeple ",
       date: 'December 29, 2023 6:0:0',
       liveDate:'September 13, 2024 6:0:0',
       category: 'Games',
       subImage:[
           {
               index:1,
               image:'item13',
               content:'"Joys visual representation of AI models as NFTs is innovative and engaging"',
           },
       ]
       },
       {
           id:2,
           image: nft7,
           subtext: '@LarvaLabs',
           title: 'Windchime #768',
           avatar: nft7,
           name:"CryptoPunks",
           liveDate:'November 29, 2024 6:0:0',
            category: 'Games',
            subImage:[
                {
                    index:4,
                    image:'item1',
                    content:'"Capturing the whimsical essence found in creative and imaginative NFT gif designs."',
               },
               
           ]
       },

       {
        id:3,
        image: nft8,
        subtext: '@HashMasks',
        title: 'Probably A Label #3277',
        avatar: nft8,
        name:"Hashmasks",
        liveDate:'March 13, 2024 6:0:0',
        category: 'Art',
        subImage:[
            {
                index:7,
                image:'item14',
                content:'"Reflecting the dynamic nature of animated NFTs, captivating attention through variation"',

            },
            
        ]
    }, 
]

export const data4 = [
    {
       id:1,
       image: nft1,
       subtext: 'MikeWinkelmann',
       title: 'Genuine Undead #3902',
       avatar: nft1,
       name:"Beeple ",
       date: 'December 29, 2023 6:0:0',
       liveDate:'September 13, 2024 6:0:0',
       category: 'Games',
       subImage:[
           {
               index:1,
               image:'item13',
               content:'"Joys visual representation of AI models as NFTs is innovative and engaging"',
           },
       ]
       },
       {
           id:2,
           image: nft2,
           subtext: '@LarvaLabs',
           title: 'Windchime #768',
           avatar: nft2,
           name:"CryptoPunks",
           liveDate:'November 29, 2024 6:0:0',
            category: 'Games',
            subImage:[
                {
                    index:4,
                    image:'item1',
                    content:'"Capturing the whimsical essence found in creative and imaginative NFT gif designs."',
               },
               
           ]
       },
       {
           id:3,
           image: nft3,
           subtext: '@HashMasks',
           title: 'Probably A Label #3277',
           avatar: nft3,
           name:"Hashmasks",
           liveDate:'March 13, 2024 6:0:0',
           category: 'Art',
           subImage:[
               {
                   index:7,
                   image:'item14',
                   content:'"Reflecting the dynamic nature of animated NFTs, captivating attention through variation"',

               },
               
           ]
       }, {
           id:4,
           image: nft4,
           subtext: '@PixelCreatorZone',
           title: 'Probably A Label #1711',
           avatar: nft4,
           name:"Pixel Creations",
           date: 'January 13, 2024 5:3:1',
           liveDate:'May 6, 2024 6:0:0',
            category: 'Music',
           subImage:[
               {
                   index:10,
                   image:item7,
               },
               {
                   index:11,
                   image:item8,
               },
               {
                   index:12,
                   image:item5,
               },
           ]
       }, {
           id:5,
           image: nft5,
           subtext: '@DreamWeaver',
           title: 'Shibuya Scramble Punks',
           avatar: nft5,
           name:"Data Dream",
           liveDate:'July 19, 2024 6:0:0',
           category: 'Video',
           subImage:[
               {
                   index:13,
                   image:item15,
               },
               {
                   index:14,
                   image:item9,
               },
               {
                   index:15,
                   image:item12,
               },
           ]
       }, {
           id:6,
           image: item11,
           subtext: '@QuantumVisions',
           title: 'Probably A Label #650',
           avatar: item11,
           name:"Quantum Studios",
           liveDate:'July 20, 2024 6:0:0',
           category: 'Games',
           subImage:[
               {
                   index:16,
                   image:item10,
               },
               {
                   index:17,
                   image:item3,
               },
               {
                   index:18,
                   image:item11,
               },
           ]
       },
]
export const images = [item13,item3,item4,item1,image12,item6, item14, item13,item5, item7, item8, item5, item15, item9, item12, item10, item3, item11, item16 ,item17, item18, item7, item9, item19, item22, item23, item24, item25, item19, item26, item27, item24, item28, item7, item14, item19  ]

export  const blogData = [
    {
        id:1,
        title: 'Mindfulness Activities for Kids & Toddlers with NFT',
        image: blog1,
        category: 'Arts',
        subtext: '@StreetBoy',
        date:"13th September,2023",
    },
    {
        id:2,
        title: 'Save Thousands Of Lives Through This NFT',
        image: blog2,
        category: 'Illustration',
        subtext: '@CutieGirl',
        date:"29th November, 2023"
    },
    {
        id:3,
        title: 'A place where technology meets craftsmanship',
        image: blog3,
        category: 'Music',
        subtext: '@ButterFly',
        date: "29th December, 2023"
    },
    {
        id:4,
        title: 'NFT Market - A Compact Trike with the Big Benefits',
        image: blog4,
        category: 'Video',
        subtext: '@NorseQueen',
        date:'13th March, 2023',
    },
    {
        id:5,
        title: 'Honoring Black History Month with Toddlers',
        image: blog5,
        category: 'Games',
        subtext: '@@BigBull',
        date:"6th June, 2023"
    },
    {
        id:6,
        title: 'Setting Intentions Instead of Resolutions for 2021',
        image: blog6,
        category: 'Memes',
        subtext: '@Angel',
        date:'19th July, 2023'
    },
    {
        id:7,
        title: 'Clever Ways to Purchase Extraordinart Items',
        image: blog7,
        category: 'GIFs',
        subtext: '@CrazyAnyone',
        date:"20th July, 2023"
    },
    {
        id:8,
        title: 'How to Save Money on Baby Essentials for NFT',
        image: blog8,
        category: 'Video',
        subtext: '@Princess',
        date:"31st August, 2023"
    },
    {
        id:9,
        title: 'Liki Trike - A Compact Trike with the Big Benefits',
        image: blog9,
        category: 'GIFs',
        subtext: '@CrazyAnyone',
        date:"13th September,2023",
    },
    {
        id:10,
        title: 'NFT Market - A Compact the Big Benefits',
        image: blog10,
        category: 'Tech',
        subtext: '@Princess',
        date:"29th November, 2023"
    },
    {
        id:11,
        title: 'Behind the Scenes of the creabik App',
        image: blog11,
        category: 'Arts',
        subtext: '@PandaOne',
        date:'13th March, 2023',
    },
    {
        id:12,
        title: 'Meet fennouni, Product Designer at GitHub',
        image: blog12,
        category: 'GIFs',
        subtext: '@FunnyGuy',
        date:'19th July, 2023'
    }
]

export  const blogData1 = [
    {
        id:1,
        title: 'Discover the Latest in ',
        title1: 'AI and Digital Art',
        image: blog1,
        category: 'Latest Insights and Innovations',
        content:'Our blog is your go-to source for the latest news, insights, and trends in AI, blockchain, and digital art. Stay ahead with in-depth articles, expert opinions, and industry updates.',
        // subtext: '@StreetBoy',
        // date:"13th September,2023",
    },
    {
        id:2,
        title: "What's New ",
        title1: "at Joy",
        image: blog2,
        category: 'Joy Updates and Announcements',
        content:'Be the first to know about new features, updates, and events at Joy. Our news section keeps you informed about everything happening in our vibrant marketplace and community',
        // subtext: '@CutieGirl',
        // date:"29th November, 2023"
    },
    
]
export const studentFeedbacks = [
    {
        id:4,
        // img:KBS,
        title:"How to create/install and use metamask wallet? ",
        videoLink:"1iMhoXLIgT0oCx1kIN6jvTQkGPFMO8sMs",
    },
    {
        id:1,
        // img: feedback3,
        title:"How to configure metamask wallet in Joy Marketplace?",
        videoLink:"1IcDW36sxAOC1TjHModX2J4869l8wvrb-",
    },
    {
        id:2,
        // img: feedback2,
        title:"How to add a test token? ",
        videoLink:"1EK_EGAyj0Pk0TXMXal_L9hmL7jvyjy5z",
    },
    {
        id:3,
        // img: feedback1,
        title:"How to create an AI model in Joy Marketplace? ",
        videoLink:"12p2H8AU4ypumaaJEPEiyTCy_eQssqP3j",
    },
    ]