import React, { useState } from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
// import img1 from '../assets/images/howdemo.jpeg'
import Switcher from '../components/switcher';
import { Link } from 'react-router-dom';
import img1 from '../../src/assets/images/training.jpg'
import {MdOutlineArrowForward} from "../assets/icons/vander"
import LOLDAO from '../assets/images/lol-new-img.png'
import { studentFeedbacks } from '../data/data';

export default function TrainingModule() {
    const [videoStates, setVideoStates] = useState(studentFeedbacks.map(() => false));

    const handleVideoClick = (index) => {
        const newVideoStates = [...videoStates];
        newVideoStates[index] = !newVideoStates[index];
        setVideoStates(newVideoStates);
    };
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(0);
        } else {
            setActiveIndex(index);
        }
    }

    const spanStyle = {
        background: `linear-gradient(90deg, #fe3499, #ffffff, #fb53a4)`,
        WebkitBackgroundClip: 'text',
        color: 'transparent',
        display: 'inline-block',
    };
    return (
        <>
            <Navbar />

            <section className="relative table w-full py-36 bg-cover" style={{ backgroundImage: `url(${img1})`, backgroundPosition: 'center' }}>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center glassmorphic6">
                        <h4 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl mb-4">Training <span style={spanStyle}> Module</span></h4>
                        <p className="text-slate-400 max-w-xl mx-auto">Training with joy</p>
                        <div className="mt-4" style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                    <img src={LOLDAO} style={{ width: '120px', height: 'auto' }} alt='LOL' />
                  </div>
                  <div className="mt-2" style={{marginLeft: '13px', display: 'flex', justifyContent: 'center', alignContent: 'center',fontSize:'16px' }}>
                    <p className="text-slate-400 max-w-xl mx-auto">DATA IS THE NEW OIL</p>
                  </div>
                    </div>
                </div>
            </section>

            <section className="relative">
                <div className="container" style={{display:'flex',justifyContent:'center',marginBottom:'70px',marginTop:'-70px'}}>
                    <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 mt-8 gap-[30px]" style={{marginBottom:'70px',marginTop:'-70px'}}
                    >
                        {/* {studentFeedbacks?.map((student, index) => (
                            <div key={index}>
                                <div className="relative iframe-youtube1" style={{background:'#000'}}>
                                    <iframe className='iframe-youtube1' src={`https://www.youtube.com/embed/${student.videoLink}?si=T7LbMebx6ftK9dmN`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="true"></iframe>
                                </div>
                            </div>
                        ))} */}
                        {studentFeedbacks?.map((student, index) => (
                            <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
                                <h5 className="tracking-[1px] text-lg text-gray-100 font-semibold text-center" style={{ marginBottom: '10px'}}>
                                    <span className="bg-gradient-to-l from-red-600 to-violet-600 text-transparent bg-clip-text">
                                        {student?.title}
                                    </span>
                                </h5>
                                <div className="relative iframe-google-drive" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <iframe
                                        className='iframe-google-drive'
                                        src={`https://drive.google.com/file/d/${student.videoLink}/preview`}
                                        title={student?.title}
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>


            <Footer />
        </>
    )
}
